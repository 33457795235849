<template>
  <div class="Credo os-font page">
    <section
      id="carouselExampleIndicators"
      class="
        carousel
        slide
        carousel-fade
        bg-credo
        vh-70
        d-flex
        justify-content-center
        align-items-center
        text-white
      "
      data-bs-ride="carousel"
    >
      <div class="carousel-inner d-flex align-items-stretch h-100">
        <!-- SLIDE 1 -->
        <div
          class="
            carousel-item
            bg-grey
            active
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div class="d-flex flex-wrap text-center justify-content-center">
              <h1 class="pb-5 px-3">Our</h1>
              <div class="pb-3" style="margin-top: -16px">
                <transition name="fade">
                  <div>
                    <h1
                      class="text-red pf-font display-1 fw-bold fst-italic grow"
                    >
                      Credo
                    </h1>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 608.6 123.1"
                      style="enable-background: new 0 0 608.6 123.1"
                      xml:space="preserve"
                    >
                      <path
                        class="st0"
                        d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                      />
                    </svg>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <!-- END -->
      </div>
    </section>
    <section class="container text-start lrg-pb">
      <h2 class="display-2 fw-3 sml-py">
        Effective IT solutions with
        <span class="pf-font fw-4 fst-italic">a cherry on top</span>
      </h2>
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col">
          <div class="card bg-pink border-0 px-5 sml-pt lrg-pb h-100">
            <img src="../assets/images/why-pie.png" class="logo pb-5" alt="" />
            <h3 class="h4 pf-font fst-italic text-white text-shadow pb-5">
              Why PiE?
            </h3>
            <p>At PiE we aim to keep things simple, effective, and fun:</p>
            <ol class="pb-5">
              <li class="p py-3">We make things as easy as pie</li>
              <li class="p py-3">Everyone likes pie</li>
              <li class="p py-3">PiE is short for "Partners in Excellence"</li>
            </ol>
            <p>
              What about the “cherry on top” then? Would you ask. The cherry on
              top is our promise to you, to always go the extra mile to show you
              new ways to expand your business and make your everyday operations
              easier.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card bg-lsgreen border-0 px-5 sml-pt lrg-pb h-100">
            <img
              src="../assets/images/work-ethics.png"
              class="logo pb-5"
              alt=""
            />
            <h3 class="h4 pf-font fst-italic text-white text-shadow pb-5">
              Our work ethic
            </h3>
            <p>
              At PiE, we will not sell you the Eiffel Tower or promise you a
              trip to the moon and back, but we will provide you with the
              straight forward solution your business needs to grow. We are
              deeply committed to offer a consultancy experience that is not
              only unique, but that also delivers results.
            </p>
            <p>
              We supply state of the art tech for a quick and efficient
              implementation to suit your specific needs, budget, and time
              frame. We constantly investigate new trends and solutions within
              our core industries, ensuring that the value we offer extends
              above and beyond the completion of a project.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card bg-yellow border-0 px-5 sml-pt lrg-pb h-100">
            <img src="../assets/images/pie-way.png" class="logo pb-5" alt="" />
            <h3 class="h4 pf-font fst-italic text-white text-shadow pb-5">
              The PiE way
            </h3>
            <p>
              Upon initial engagement, we discuss your goal and identify the
              core challenges to implement a winning strategy. Knowledge is
              power; we dig deep into the analysis of your data and market using
              the best analytics tools to excavate your growth potential. Then,
              we discuss the best possible roads to travel together to take your
              business to your dream destination.
            </p>

            <p>
              We walk you through all the stages of your project providing
              management, training, and support to your team from A to Z.
            </p>

            <p>
              Sharing is caring! At PiE Digital, we believe that openness and
              fluid communication are keys to success. We keep you informed
              through regular detailed reports and analysis on how your strategy
              is achieved.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="container text-start lrg-pb">
      <h2 class="display-2 fw-3 sml-py">
        Our favourite
        <span class="pf-font fw-4 fst-italic">gimmicks</span>
      </h2>
      <div class="row">
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4>Airtable</h4>
        </div>
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4>Shopify</h4>
        </div>
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4>Tenzo</h4>
        </div>
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4>Lightspeed</h4>
        </div>
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4>Cognito Forms</h4>
        </div>
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4>MarketMan</h4>
        </div>
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4>Power BI</h4>
        </div>
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4>....and so many more!</h4>
        </div>
        <div class="col-md-4 pf-font fst-italic py-4">
          <h4></h4>
        </div>
      </div>
    </section>

    <section>
      <div class="row d-flex justify-content-center flex-nowrap w-100 m-0">
        <div class="flex-fill m-0 p-0">
          <img
            src="../assets/images/brainstorming.webp"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="flex-fill m-0 p-0">
          <img
            src="../assets/images/colleagues.webp"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Credo",
  components: {},
};
</script>

<style scoped>
.st0 {
  fill: none;
  stroke: none;
  stroke-width: 22;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st0 {
  fill: none;
  stroke: var(--gold);
  stroke-width: 22;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}
.grow {
  transform: scale(0);
}
.active .grow {
  transform: scale(1);
  transition: 1s;
}

.st1 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 14;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st1 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 14;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}

.st2 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 8;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st2 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 8;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

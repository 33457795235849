<template>
  <div class="Credo os-font fw-3 page">
    <section
      id="carouselExampleIndicators"
      class="
        carousel
        slide
        carousel-fade
        bg-credo
        vh-70
        d-flex
        justify-content-center
        align-items-center
        text-white
      "
      data-bs-ride="carousel"
    >
      <div class="carousel-inner d-flex align-items-stretch h-100">
        <!-- SLIDE 1 -->
        <div
          class="
            carousel-item
            bg-grey
            active
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div class="d-flex flex-wrap text-center justify-content-center">
              <h1 class="pb-5 px-3">Let's</h1>
              <div class="pb-3" style="margin-top: -16px">
                <transition name="fade">
                  <div>
                    <h1
                      class="
                        text-yellow
                        pf-font
                        display-1
                        fw-bold
                        fst-italic
                        grow
                      "
                    >
                      Chat
                    </h1>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 608.6 123.1"
                      style="enable-background: new 0 0 608.6 123.1"
                      xml:space="preserve"
                    >
                      <path
                        class="st3-1"
                        d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                      />
                    </svg>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <!-- END -->
      </div>
    </section>
    <section class="container text-start lrg-pb">
      <div class="row sml-py">
        <div class="col-md-6 px-5">
          <p>
            Whether we are in rainy London or in sunny Cape Town, we do work
            across the globe in multiple time zones. Do not hesitate to reach
            out!
          </p>
        </div>
        <div class="col-md-6">
          <form class="px-5">
            <div class="row mb-3">
              <div class="col">
                <label
                  for="exampleInputEmail1"
                  class="form-label fw-normal fst-italic"
                  >First Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  aria-label="First name"
                  v-model="firstName"
                />
              </div>
              <div class="col">
                <label
                  for="exampleInputEmail1"
                  class="form-label fw-normal fst-italic"
                  >Last Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  aria-label="Last name"
                  v-model="lastName"
                />
              </div>
            </div>
            <div class="mb-3">
              <label
                for="exampleInputEmail1"
                class="form-label fw-normal fst-italic"
                >Email address</label
              >
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                v-model="email"
              />
              <div id="emailHelp" class="form-text small-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div class="mb-3">
              <label
                for="exampleInputEmail1"
                class="form-label fw-normal fst-italic"
                >Phone(optional)</label
              >
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                v-model="number"
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleInputPassword1"
                class="form-label fw-normal fst-italic"
                >Message</label
              >
              <textarea
                type="password"
                class="form-control"
                id="exampleInputPassword1"
                rows="4"
                v-model="message"
              />
            </div>

            <button
              class="w-100 btn btn-lg text-white bg-dark btn-main mx-auto"
              type="button"
              @click="getInContact()"
            >
              <div
                v-if="loading"
                class="spinner-border spinner-border-md text-white"
              ></div>
              <span v-if="loading"> <h5>Sending</h5></span>
              <span v-else
                ><h5>{{ title }}</h5></span
              >
            </button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "Credo",
  components: {},
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      firstName: "",
      lastName: "",
      number: "",
      email: "",
      message: "",
    };
  },
  methods: {
    getInContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        this.axios
          .post(
            "/PHPMailer/src/getInContact.php",

            qs.stringify({
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              number: this.number,
              message: this.message,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.number = "";
            this.message = "";
            console.log(response);
          })
          .catch((error) => {
            this.title = "Something went wrong";
            console.log(error);
          });
      }
    },
  },
};
</script>

<template>
  <div class="Consulting os-font page">
    <section
      id="carouselExampleIndicators"
      class="
        carousel
        slide
        carousel-fade
        bg-credo
        vh-70
        d-flex
        justify-content-center
        align-items-center
        text-white
      "
      data-bs-ride="carousel"
    >
      <div class="carousel-inner d-flex align-items-stretch h-100">
        <!-- SLIDE 1 -->
        <div
          class="
            carousel-item
            bg-grey
            active
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div class="d-flex flex-wrap text-center justify-content-center">
              <h1 class="pb-5 px-3">Consulting</h1>
              <div class="pb-3" style="margin-top: -16px">
                <transition name="fade">
                  <div>
                    <h1
                      class="
                        text-yellow
                        pf-font
                        display-1
                        fw-bold
                        fst-italic
                        grow
                      "
                    >
                      Formulas
                    </h1>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 608.6 123.1"
                      style="enable-background: new 0 0 608.6 123.1"
                      xml:space="preserve"
                    >
                      <path
                        class="st1"
                        d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                      />
                    </svg>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <!-- END -->
      </div>
    </section>
    <section class="container text-start lrg-pb">
      <h2 class="display-2 fw-3 sml-py">
        All you need is
        <span class="pf-font fw-4 fst-italic">PiE</span>
      </h2>
      <div class="row fw-3">
        <div class="col-lg-7 col-md-6 type"></div>
        <div
          class="
            col-lg-5 col-md-6
            d-flex
            flex-column
            align-items-start
            justify-content-center
            ps-4
          "
        >
          <h2 class="fw-3">Come as you are</h2>
          <p>
            Because your business comes with a unique background, a singular
            story, a distinctive concept, we want you to feel special every step
            of the way.
          </p>
        </div>
      </div>
      <div class="row fw-3 my-5">
        <div
          class="
            col-lg-5 col-md-6
            d-flex
            flex-column
            align-items-start
            justify-content-center
            ps-4
            my-5
          "
        >
          <h2 class="fw-3">At PiE we want to make it all about you</h2>
          <p>
            To stay competitive, no serious business can avoid digitalization,
            be it through your internal processes or to reach your target
            audience. More than ever, online and offline customer experiences
            write hand in hand their intricate stories… predicting the longevity
            of your company.
          </p>
          <p>
            We know that for many people, IT systems are still uncharted
            territories, and therefore we won’t bore you with the technical
            stuff. We are also business owners, we understand your legitimate
            interrogations and, more than anything else, we talk your language.
          </p>
          <p>
            We pride ourselves in delivering crystal-clear workflows providing
            you with a hands-on understanding of your very own system. You stay
            involved in the execution of the project every step of the way.
          </p>
        </div>

        <div
          class="
            col-lg-7 col-md-6
            camera
            py-5
            d-flex
            align-items-stretch
            justify-content-stretch
          "
        ></div>
      </div>
      <div class="row fw-3">
        <div
          class="
            col-lg-7 col-md-6
            order-last order-md-first
            lady
            py-5
            d-flex
            align-items-stretch
            justify-content-stretch
          "
        ></div>
        <div
          class="
            col-lg-5 col-md-6
            order-first order-md-last
            d-flex
            flex-column
            align-items-start
            justify-content-center
            ps-4
            my-5
          "
        >
          <h2 class="fw-3">Let’s make your IT work for you</h2>
          <p>Are you sure you and your staff are at capacity?</p>
          <p>
            Is your staff schedule the best fit for your business rush hours?
          </p>
          <p>Is your on-boarding process efficient enough?</p>
          <p>
            Are there areas in your business where you can cut costs and
            dramatically increase profitability?
          </p>
          <p>
            Those are just a few examples of the areas where automation can turn
            your world around. Today, analytics tools provide us with quality
            data like never before, allowing you to make educated decisions and
            measure their impact. We will dig into your tech infrastructure to
            identify the areas needing urgent optimization and implement swift
            solutions.
          </p>
          <p>
            We are offering a holistic approach to your system for you to get
            more bangs for your bucks.
          </p>
        </div>
      </div>
    </section>
    <section class="container text-start lrg-pb">
      <h2 class="display-2 fw-3 sml-py">
        Our
        <span class="pf-font fw-4 fst-italic">tempo</span>
      </h2>
      <div class="row fw-3">
        <div class="col-lg-5 col-md-6">
          <h2 class="fw-3">Short-term partnership to achieve rapid growth</h2>
          <p>
            Let’s keep things cheap and cheerful! <br />
            Because sometimes you have a single issue that requires a quick
            reply, we specialize in no-code solutions. We can rapidly implement
            great tools to improve your productivity and make your daily life
            easier.
          </p>
          <br />
          <p>We will</p>
          <ul>
            <li>
              <p>Select the very best available Apps to address your need</p>
            </li>
            <li>
              <p>
                Create new and flexible ways to make them work together(APIs)
              </p>
            </li>
            <li><p>Train your team to use your new system</p></li>
          </ul>
          <p>
            Automating tasks will free up time for you and your team to focus on
            more strategic topics, increasing profitability.
          </p>
        </div>
        <div class="col-lg-7 col-md-6 staff"></div>
      </div>
      <div class="row fw-3 py-5">
        <div
          class="col-lg-7 col-md-6 vr-headset order-last order-md-first"
        ></div>
        <div class="col-lg-5 col-md-6">
          <h2 class="fw-3">
            Long-term partnership to make your business future-proof
          </h2>
          <p>
            We are also performing in-depth business and IT system audits to
            build ad-hoc solutions.
          </p>
          <p>
            Once your challenges are clearly identified and your goals are set,
            we can launch a coding protocol creating new ways for you to trade.
          </p>
          <p>
            A long-haul growth strategy can also include digital marketing
            solutions, website optimization, SEO, content copywriting and
            anything you need to increase your customer engagement and loyalty
            in the long run.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Consulting",
  components: {},
};
</script>

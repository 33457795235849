<template>
  <div>
    <router-link to="/chat" class="text-decoration-none">
      <section
        class="
          cta
          text-white
          d-flex
          justify-content-center
          align-items-center
          sml-pt
          ps-md-5
        "
      >
        <div class="row w-100 brief-chat ps-md-5">
          <div class="col-12">
            <div class="row mx-auto d-flex justify-content-center">
              <div class="col-sm-5">
                <h2
                  class="
                    fw-light
                    pe-md-5 pe-3
                    pt-1
                    chat-text
                    text-md-end text-start
                  "
                >
                  Got a brief ?
                </h2>
              </div>
              <div class="col-12 col-sm-7 w-sm-100">
                <div class="row w-100">
                  <div class="d-flex justify-content-start w-100">
                    <h2
                      class="
                        fw-light
                        fst-italic
                        pf-font
                        chat-text
                        text-md-start text-center
                      "
                    >
                      Let's chat !
                    </h2>
                    <div class="curved-line">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="inline"
                      >
                        <path
                          class="st4"
                          d="M14.5,378.5c0,0,96-191.7,244.5-261.5c151-71,337-25,297,164c-27.5,130.2-218,167-300,71
	c-32-37.4-40.2-129.1,4-192C316.5,79.6,392.6,52.1,458,34c181.5-50.2,403.9,175.7,427.9,197.9c0.1,0,0.1,0.1,0.1,0.1
	c1.3,1.2-6.5-86.5-7.5-86.5s9.9,86.1,7.7,87.3s-95.7,0.7-95.7,0.7"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </router-link>
    <div class="bg-black">
      <div class="container">
        <div class="row sml-py text-white">
          <div class="col-12 text-start">
            <img
              src="../assets/images/pie-logo-white.svg"
              class="logo"
              alt=""
            />
          </div>
          <div class="col-md-6 py-5">
            <p class="text-start">
              System Efficiency, Integration, Automation, EPOS & Business
              Analytics, we provide tailor-made innovative IT solutions to make
              your business thrive in the digital world.
            </p>
          </div>
          <div class="col-0 col-md-6"></div>
          <div class="col-sm-6 col-12 d-flex flex-column">
            <router-link to="/chat" class="text-decoration-none">
              <h5 class="text-start text-white">Get in touch</h5>
            </router-link>
            <a
              href="tel:+442035761263"
              class="text-start text-white p text-decoration-none"
              >(+44) 020 3576 1263</a
            >
          </div>
          <div class="col-sm-6 col-12">
            <h5 class="text-sm-end text-start">
              follow us:
              <a
                href="https://www.linkedin.com/company/pie-digital-partners-in-excellence/"
                target="_blank"
                ><i class="fab fa-linkedin text-white"></i
              ></a>
            </h5>
          </div>
          <div class="col-12"><p>© pie.digital</p></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* .st4 {
  fill: none;
  stroke: #000000;
  stroke-width: 6;
  stroke-miterlimit: 10;
  transform: scale(0.5);
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
} */
.st4 {
  fill: none;
  stroke: #fff;
  stroke-width: 22;
  stroke-miterlimit: 10;
  transform: scale(0.1);
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  animation: none;
  animation-delay: 0.5s;
  transform: rotate(20deg);
}
.cta:hover .st4 {
  fill: none;
  stroke: #fff;
  stroke-width: 26;
  stroke-miterlimit: 10;
  transform: scale(0.1) rotate(10deg);
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  animation: dash 1s linear forwards;
  animation-delay: 0.5s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.st5 {
  transform: scale(0.4);
  transition: 1s;
  transition-delay: 0s;
  opacity: 0;
  fill: transparent;
}
.brief-chat:hover .st5 {
  fill: white;
  transform: scale(0.4);
  transition-delay: 1.7s;
  opacity: 1;
}
.curved-line {
  margin-top: -20px;
}
.inline {
  width: 100px !important;
  height: 100px;
  margin-top: 20px;
  margin-left: 20px;
}
.cta {
  background-color: var(--red);
  transition: 1s;
}
.cta:hover {
  background-color: var(--light-sea-green);
  transition: 1s;
}
</style>

<template>
  <div class="home h-100 page">
    <section
      id="carouselExampleIndicators"
      class="carousel slide carousel-fade h-100"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active bg-light"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          class="bg-light"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          class="bg-light"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="3"
          class="bg-light"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="4"
          class="bg-light"
          aria-label="Slide 5"
        ></button>
      </div>
      <div class="carousel-inner d-flex align-items-stretch min-vh-100">
        <!-- SLIDE 1 -->
        <div
          class="
            carousel-item
            active
            bg-sgreen
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div class="d-flex flex-wrap text-center justify-content-center">
              <h1 class="pb-5 px-3">We make new</h1>
              <div class="pb-3" style="margin-top: -20px">
                <transition name="fade">
                  <div>
                    <h1
                      class="text-red pf-font display-1 fw-bold fst-italic grow"
                    >
                      technologies
                    </h1>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 608.6 123.1"
                      style="enable-background: new 0 0 608.6 123.1"
                      xml:space="preserve"
                    >
                      <path
                        class="st0"
                        d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                      />
                    </svg>
                  </div>
                </transition>
              </div>
              <h1 class="pb-5 px-3">work for you</h1>
            </div>

            <p>
              Are you sure you are getting the most of your IT system? We can
              improve your processes to increase productivity, allowing your
              team to spend more time on the tasks that matter. Welcome to the
              wonderful world of automation!
            </p>
          </div>
        </div>
        <!-- END -->
        <!-- SLIDE 2 -->
        <div
          class="
            carousel-item
            bg-lsgreen
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div
              class="d-flex flex-wrap text-center justify-content-center pt-5"
            >
              <h1 class="pb-5 px-3">Demonstrated experience in</h1>
              <div class="pb-3" style="margin-top: -20px">
                <h1 class="text-red pf-font display-1 fw-bold fst-italic grow">
                  large-scale
                </h1>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 608.6 123.1"
                  style="enable-background: new 0 0 608.6 123.1"
                  xml:space="preserve"
                >
                  <path
                    class="st0"
                    d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                  />
                </svg>
              </div>
              <h1 class="pb-5 px-3">
                event management solutions
              </h1>
            </div>

            <p>
              We have been operating the tech-side of the only large-scale event
              held in the UK in 2020. Therefore, we have developed and mastered
              technological quick responses to the Covid-19 challenges. Discover
              how we can help you run your business safely for your staff and
              customers moving forward.
            </p>
          </div>
        </div>
        <!-- END -->
        <!-- SLIDE 3 -->
        <div
          class="
            carousel-item
            bg-pink
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div class="d-flex flex-wrap text-center justify-content-center">
              <h1 class="pb-5 px-3">Tailor-made,</h1>
              <div class="pb-3" style="margin-top: -20px">
                <h1
                  class="text-yellow pf-font display-1 fw-bold fst-italic grow"
                >
                  effective
                </h1>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 608.6 123.1"
                  style="enable-background: new 0 0 608.6 123.1"
                  xml:space="preserve"
                >
                  <path
                    class="st1"
                    d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                  />
                </svg>
              </div>
              <h1 class="pb-5 px-3">and innovative IT solutions</h1>
            </div>

            <p>
              No business is like another, but “success leaves clues”. Over the
              years, our team has helped business owners take their companies to
              the next level. Together, let’s create a blueprint to your success
              in the digital era.
            </p>
          </div>
        </div>
        <!-- END -->
        <!-- SLIDE 4 -->
        <div
          class="
            carousel-item
            bg-yellow
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div class="d-flex flex-wrap text-center justify-content-center">
              <h1 class="pb-5 px-3">Result driven and</h1>
              <div class="pb-3" style="margin-top: -20px">
                <h1 class="text-red pf-font display-1 fw-bold fst-italic grow">
                  solution-oriented
                </h1>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 608.6 123.1"
                  style="enable-background: new 0 0 608.6 123.1"
                  xml:space="preserve"
                >
                  <path
                    class="st2"
                    d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                  />
                </svg>
              </div>
              <h1 class="pb-5 px-3">team of experts</h1>
            </div>
            <p>
              What matters to us is where you want to go. Thanks to our business
              analysis and data-driven decision making process, we will take you
              there.
            </p>
          </div>
        </div>
        <!-- END -->
        <!-- SLIDE 5 -->
        <div
          class="
            carousel-item
            bg-lsgreen
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div class="d-flex flex-wrap text-center justify-content-center">
              <h1 class="pb-5 px-3">Website optimization:</h1>
              <div class="pb-5" style="margin-top: -20px">
                <h1 class="text-red pf-font display-1 fw-bold fst-italic grow">
                  UX design,
                </h1>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 608.6 123.1"
                  style="enable-background: new 0 0 608.6 123.1"
                  xml:space="preserve"
                >
                  <path
                    class="st0"
                    d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                  />
                </svg>
              </div>
              <h1 class="pb-5 px-3">high quality content and SEO</h1>
            </div>

            <p>
              Whether you are new to the digital game or a serious player, we
              will help you optimize your digital presence to improve your
              ranking and your conversion rate through impeccable customer
              experience.
            </p>
          </div>
        </div>
        <!-- END -->
      </div>
      <!-- <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button> -->
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: () => ({}),
};
</script>

<style scoped>
.st0 {
  fill: none;
  stroke: none;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st0 {
  fill: none;
  stroke: var(--gold);
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}
.grow {
  transform: scale(0);
}
.active .grow {
  transform: scale(1);
  transition: 1s;
}

.st1 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 14;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st1 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 14;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}

.st2 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 8;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st2 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 8;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

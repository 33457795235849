<template>
  <div class="Credo os-font fw-3 page">
    <section id="carouselExampleIndicators" class="
        carousel
        slide
        carousel-fade
        bg-credo
        vh-70
        d-flex
        justify-content-center
        align-items-center
        text-white
      " data-bs-ride="carousel">
      <div class="carousel-inner d-flex align-items-stretch h-100">
        <!-- SLIDE 1 -->
        <div class="
            carousel-item
            bg-grey
            active
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          ">
          <div class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            ">
            <div class="d-flex flex-wrap text-center justify-content-center">
              <h1 class="pb-5 px-3">Meet the</h1>
              <div class="pb-3" style="margin-top: -16px">
                <transition name="fade">
                  <div>
                    <h1 class="
                        text-red
                        pf-font
                        display-1
                        fw-bold
                        fst-italic
                        grow
                      ">
                      Partners
                    </h1>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 608.6 123.1"
                      style="enable-background: new 0 0 608.6 123.1" xml:space="preserve">
                      <path class="st0" d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50" />
                    </svg>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <!-- END -->
      </div>
    </section>
    <section class="container text-start lrg-pb pt-5">
      <div class="row d-flex justify-content-center">
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://prettysim.pl" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/ps-logo.svg" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Prettysimpl<br><small>Automation</small>
            </p>
          </a>

        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://www.gotenzo.com/" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/Tenzo-logo.svg" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Tenzo<br><small>Business Analytics</small> </p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://bit.ly/3kT4lIY" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/workforce.svg" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Workforce<br><small>HR Management</small> </p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://www.twicebaked.uk/" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/TB-logo-02-red.png" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Twice Baked<br><small>Consulting</small> </p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://www.zettle.com/gb" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/zettle.svg" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Zettle by Paypal<br><small>Integrated Payment Solutions</small>
            </p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://www.marketman.com/" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/MarketMan-Logo.png" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">MarketMan<br><small>Inventory Management</small></p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://www.lightspeedhq.com/" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/lightspeed.svg" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Lightspeed<br><small>ePOS</small></p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://www.deliverect.com/en" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/Deliverect.svg" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Deliverect<br><small>Delivery Management</small> </p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://www.growthlondon.uk/" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/growth-london-blue.svg" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Hubspot via Growth London<br><small>CRM</small></p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6 pf-font fst-italic py-4">
          <a href="https://www.supportwizard.net/" target="_blank" class=" text-decoration-none">
            <div class="shadow logos mx-auto">
              <img src="../assets/images/swz.png" class="img-fluid" alt="">
            </div>
            <p class="text-muted mt-3 mb-0 text-center">Support Wizard<br><small>Infrastructure Support</small></p>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "Credo",
  components: {},
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      firstName: "",
      lastName: "",
      number: "",
      email: "",
      message: "",
    };
  },
  methods: {
    getInContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        this.axios
          .post(
            "/PHPMailer/src/getInContact.php",

            qs.stringify({
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              number: this.number,
              message: this.message,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.number = "";
            this.message = "";
            console.log(response);
          })
          .catch((error) => {
            this.title = "Something went wrong";
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.logos {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: 0.5s;
}

.logos img {
  max-width: 100%;
  max-height: 100%;
}

a:hover .shadow {
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.318) !important;
}

a .text-muted {
  transition: 0.5s;
  font-weight: bold;
}

a .text-muted small {
  transition: 0.5s;
  font-weight: normal;
}

a:hover .text-muted {
  color: black !important;
}
</style>
<template>
  <nav id="nav" class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
    <div class="container">
      <router-link class="navbar-brand" to="/"><img src="../assets/images/pie-logo-black.svg" class="img-fluid logo"
          alt="" /></router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item px-3">
            <router-link class="nav-link fw-light" to="/">Home</router-link>
          </li>
          <li class="nav-item px-3">
            <router-link class="nav-link fw-light" to="/credo">Credo</router-link>
          </li>
          <li class="nav-item px-3">
            <router-link class="nav-link fw-light" to="/consulting">Consulting</router-link>
          </li>
          <li class="nav-item px-3">
            <router-link class="nav-link fw-light" to="/expertise">Expertise</router-link>
          </li>
          <li class="nav-item px-3">
            <router-link class="nav-link fw-light" to="/partners">Meet the partners</router-link>
          </li>
          <li class="nav-item px-3">
            <router-link class="nav-link fw-light" to="/chat">Let's chat</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
};
</script>
<style>
.navbar-nav {
  overflow: hidden;
  /* background-color: #f1f1f1; */
}

.navbar-nav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 2px solid transparent;
}

.navbar-nav a:hover {
  border-bottom: 2px solid black;
}

#nav a.router-link-exact-active {
  border-bottom: 2px solid black !important;
  color: var(--red) !important;
}

#nav a.router-link-exact-active.navbar-brand {
  border-bottom: 2px solid transparent !important;
  color: var(--red) !important;
}
</style>

<template>
  <div class="Expertise os-font page">
    <section
      id="carouselExampleIndicators"
      class="
        carousel
        slide
        carousel-fade
        bg-expert
        vh-70
        d-flex
        justify-content-center
        align-items-center
        text-white
      "
      data-bs-ride="carousel"
    >
      <div class="carousel-inner d-flex align-items-stretch h-100">
        <!-- SLIDE 1 -->
        <div
          class="
            carousel-item
            bg-grey
            active
            d-flex
            align-items-stretch
            justify-content-stretch
            lrg-py
          "
        >
          <div
            class="
              container
              text-white text-shadow
              px-10
              d-flex
              align-items-stretch
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div
              class="d-flex flex-wrap text-center justify-content-center pt-5"
            >
              <h1 class="pb-5 px-3">Areas of</h1>
              <div class="pb-3" style="margin-top: -16px">
                <transition name="fade">
                  <div>
                    <h1
                      class="
                        text-yellow
                        pf-font
                        display-1
                        fw-bold
                        fst-italic
                        grow
                      "
                    >
                      Expertise
                    </h1>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 608.6 123.1"
                      style="enable-background: new 0 0 608.6 123.1"
                      xml:space="preserve"
                    >
                      <path
                        class="st3"
                        d="M17.8,66.8c0,0,104.5-42.8,428-44c139.5-0.5,151,30,151,50"
                      />
                    </svg>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <!-- END -->
      </div>
    </section>
    <section class="container text-start sml-pb pt-4">
      <h2 class="display-2 fw-3 sml-py">
        Hospitality is where our
        <span class="pf-font fw-4 fst-italic">heart is</span>
      </h2>
      <p>
        Our founders and all our expert consultants share a solid background in
        IT systems and a passion for the Hospitality Industry.
      </p>
      <p>
        Because we believe in delivering services we love to clients we fully
        understand, we are naturally driven towards (but not limited to)
        hospitality and events.
      </p>
      <p>
        We believe our clients come to us for our “cherry on top”; because we
        are cross channel specialists passionate about food, seamless IT
        experiences and customer satisfaction.
      </p>
      <p>
        Whether you are a one-location business aiming to boost your revenue or
        an ambitious player wanting to conquer the world with an innovative
        franchise; we are here to elevate your system game.
      </p>
      <p>
        We turn your hopes into plan to scale and grow your business. Tell us
        where you want to go, and we will find (or create) the tools to take you
        there.
      </p>
    </section>
    <section class="container text-start lrg-pb pt-5">
      <h2 class="display-2 fw-3 sml-pb">
        Efficiency &#38; Automation are the
        <span class="pf-font fw-4 fst-italic">names of our game </span>
      </h2>
      <p>
        PiE is a unique group of consultants who have spent over 20 years
        investigating and studying the habits of consumers, buyers and suppliers
        alike in the Retail, Supply Chain, Hospitality, Events and IT industries
        to deliver the most efficient IT solutions available. We offer a wide
        array of expertise giving you the necessary knowledge and tools to
        reduce costs, improve efficiency and most importantly grow your
        business.
      </p>
      <p>
        Our team has worked with international retailers, supply chains, event
        companies and technology solutions providers ranging from £500,000 to
        £1BN in the UK & Europe, US, Middle East and Asia, giving us an
        unrivalled expertise on the large-scale side of things. We also love
        interacting with smaller businesses and giving them the digital push
        they need to thrive.
      </p>
    </section>
    <section class="container text-start sml-pb">
      <div class="row fw-3">
        <div
          class="col-lg-5 col-md-6 d-none d-xl-block"
          style="position: relative"
        >
          <h2
            class="display-2 fw-3 sml-py"
            style="position: absolute; top: 150px; right: -400px; z-index: 100"
          >
            (Expertise in IT + Field Knowledge) x Data-Driven Decision =
            <span class="pf-font fw-4 fst-italic"
              >your Business Growth Formula
            </span>
          </h2>
        </div>
        <div
          class="col-lg-5 col-md-6 d-none d-lg-block d-xl-none"
          style="position: relative"
        >
          <h2
            class="display-2 fw-3 sml-py"
            style="position: absolute; top: -50px; right: -200px; z-index: 100"
          >
            (Expertise in IT + Field Knowledge) x Data-Driven Decision =
            <span class="pf-font fw-4 fst-italic"
              >your Business Growth Formula1
            </span>
          </h2>
        </div>
        <div
          class="col-lg-5 col-md-6 d-none d-md-block d-lg-none"
          style="position: relative"
        >
          <h2
            class="display-2 fw-3 sml-py"
            style="position: absolute; top: -50px; right: -200px; z-index: 100"
          >
            (Expertise in IT + Field Knowledge) x Data-Driven Decision =
            <span class="pf-font fw-4 fst-italic"
              >your Business Growth Formula1
            </span>
          </h2>
        </div>
        <div class="col-lg-5 col-md-6 d-block d-md-none">
          <h2 class="display-2 fw-3 sml-py">
            (Expertise in IT + Field Knowledge) x Data-Driven Decision =
            <span class="pf-font fw-4 fst-italic"
              >your Business Growth Formula
            </span>
          </h2>
        </div>
        <div class="col-lg-7 col-md-6">
          <img src="../assets/images/balloons.webp" class="img-fluid" alt="" />
        </div>

        <div class="col-12">
          <h2 class="display-2 fw-3 sml-py">
            System
            <span class="pf-font fw-4 fst-italic">Infrastructure </span>
          </h2>
          <p>Simplify, automate and upscale your business!</p>
          <p>
            Selecting the best solutions for your business is a long and costly
            process: let us make it easy for you.
          </p>
          <p>
            Our cloud-based low-code approach allows for rapid deployment of
            automations that increase efficiency, reduce costs and deliver
            accurate reporting.
          </p>
          <p>
            Say goodbye to inefficient processes, misaligned applications, and
            dead-end legacy systems!
          </p>
        </div>
        <div class="col-lg-7 col-md-6 light-mouse my-5"></div>
        <div
          class="
            col-lg-5 col-md-6
            d-flex
            flex-column
            align-items-start
            justify-content-center
            ps-4
          "
        >
          <h2 class="fw-3">Internal process</h2>
          <p>
            Our team offers an unmatched ability to provide technological
            support and assistance, paired with a deep understanding of our
            clients’ background.
          </p>
          <p>
            Eliminate back-office processes, reduce time-consuming tasks and
            automate business activities to focus on what matters most: your
            customers.
          </p>
        </div>
        <div
          class="
            col-lg-5 col-md-6
            d-flex
            flex-column
            align-items-start
            justify-content-center
            ps-4
          "
        >
          <h2 class="fw-3">External process</h2>
          <p>
            A customer's interaction with a product, service or brand is vital
            to ensure your customer's experience is of the highest calibre.
          </p>
          <p>
            PiE helps you to connect with your customers by combining the online
            and offline experience. We take your business strategy and implement
            this at every touch point between your business and your audience.
          </p>
          <p>
            We constantly invent new ways of promoting this relationship through
            service and technology, helping you to deliver a truly improved
            customer experience that holds the core values of your brand and
            increases sales.
          </p>
        </div>
        <div class="col-lg-7 col-md-6 external my-5"></div>
      </div>
    </section>
    <section class="container text-start lrg-pb">
      <h2 class="display-2 fw-3 sml-py">
        Business
        <span class="pf-font fw-4 fst-italic">Analysis & Operations</span>
      </h2>
      <p>
        We work closely with our clients to develop and shape effective
        objectives to deliver successful implementation. We review all internal
        processes such as organization, stock, inventory management, systems to
        develop and implement a strategy.
      </p>
      <p>
        This strategy is then translated into a system giving your business the
        necessary tools to scale and meet your objectives and KPI's. The diverse
        experience of our team ensures understanding of what is required to
        achieve objectives, timelines and most importantly results.
      </p>
      <p>
        We will implement any changes in your business through introduction of
        new services and suppliers, ensuring accuracy, delivery and set up
        within timescales. We will assist in the training process, and we can
        supply staff for specific business areas or functions if required.
      </p>
      <div class="row fw-3">
        <div class="col-lg-7 col-md-6 lightbulb my-5"></div>
        <div
          class="
            col-lg-5 col-md-6
            d-flex
            flex-column
            align-items-start
            justify-content-center
            ps-4
          "
        >
          <h3 class="fw-3">To sum up, we can help with:</h3>
          <ul class="">
            <li class="p">Efficiency Analysis & Optimization</li>
            <li class="p">Business Intelligence & Reporting</li>
            <li class="p">New Process Implementation</li>
            <li class="p">Business Strategy Development</li>
            <li class="p">Immersive & Innovative Solutions</li>
            <li class="p">App Development</li>
            <li class="p">COVID-19 Management Solutions</li>
            <li class="p">EPOS (Point of Sale) & Supply Chain Systems</li>
            <li class="p">Web Design, Digital Marketing & E-commerce</li>
            <li class="p">SEO Optimization & Copywriting</li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

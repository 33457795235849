<template>
  <Header></Header>
  <router-view v-slot="{ Component }">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" />
    </transition>
  </router-view>

  <Footer></Footer>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
:root {
  --red: #c53034;
  --black: #030102;
  --pink: #f56d6f;
  --blue: #4793e8;
  --sea-green: #1d6063;
  --light-sea-green: #5abfb6;
  --yellow: #f4d15e;
  --slate-grey: #085f63;
  --gold: #facf5a;
}
html {
  font-size: 10px;
}
.os-font {
  font-family: "Open Sans", sans-serif;
}

.pf-font {
  font-family: "Playfair Display", serif;
}

.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}
/* COLOURS */
.bg-red {
  background: var(--red);
}
.bg-black {
  background: var(--black);
}
.bg-pink {
  background: var(--pink);
}
.bg-blue {
  background: var(--blue);
}
.bg-sgreen {
  background: var(--sea-green);
}
.bg-lsgreen {
  background: var(--light-sea-green);
}
.bg-yellow {
  background: var(--yellow);
}

.text-red {
  color: var(--red);
}
.text-black {
  color: var(--black);
}
.text-pink {
  color: var(--pink);
}
.text-blue {
  color: var(--blue);
}
.text-sgreen {
  color: var(--sea-green);
}
.text-lsgreen {
  color: var(--light-sea-green);
}
.text-yellow {
  color: var(--yellow);
}
/* FONT + SIZE */
.display-1 {
  font-size: 6.5rem;
  line-height: 7rem;
}
.display-2 {
  font-size: 5.8rem;
  line-height: 6.2rem;
}
.display-3 {
  font-size: 5rem;
  line-height: 6rem;
}

h1,
.h1 {
  font-size: 4.5rem;
}
h2,
.h2 {
  font-size: 4rem;
}
h3,
.h3 {
  font-size: 3.8rem;
}
h4,
.h4 {
  font-size: 3.2rem;
}
h5,
.h5 {
  font-size: 2.4rem;
}
h6,
.h6 {
  font-size: 2rem;
}
p,
.p {
  font-size: 1.8rem;
  font-weight: 400;
}
.small-text {
  font-size: 1.4rem;
}

.nav-link,
.form-control,
.form-label {
  font-size: 1.8rem;
}
.logo {
  width: 120px;
}
/* UTILS */
.carousel-indicators {
  bottom: 50px;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vh-30 {
  height: 30vh;
}
.vh-40 {
  height: 40vh;
}
.vh-50 {
  height: 50vh;
}
.vh-60 {
  height: 60vh;
}
.vh-70 {
  height: 70vh;
}
.vh-80 {
  height: 80vh;
}
.vh-90 {
  height: 90vh;
}
.px-10 {
  padding-right: 10%;
  padding-left: 10%;
}
.box-shadow {
  box-shadow: 1px 1px 6px 3px black;
}
.text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
/* .fw-light {
  font-weight: bold !important;
} */

/* PADDING AND MARGINS */
.sml-py {
  padding-top: 50px;
  padding-bottom: 50px;
}
.sml-px {
  padding-left: 50px;
  padding-right: 50px;
}
.lrg-py {
  padding-top: 100px;
  padding-bottom: 100px;
}
.lrg-px {
  padding-left: 100px;
  padding-right: 100px;
}
.sml-pt {
  padding-top: 50px;
}
.sml-ps {
  padding-left: 50px;
}
.lrg-pt {
  padding-top: 100px;
}
.lrg-ps {
  padding-left: 100px;
}
.sml-pb {
  padding-bottom: 50px;
}
.sml-pe {
  padding-right: 50px;
}
.lrg-pb {
  padding-bottom: 100px;
}
.lrg-pe {
  padding-right: 100px;
}

/* PAGE BACKGROUNDS */
.bg-credo {
  background: url("./assets/images/bg-credo.webp");
}
.bg-expert {
  background: url("./assets/images/expert.webp");
}

/* SECTION BACKGROUNDS */

.light-mouse {
  background: url("./assets/images/light-mouse.webp");
  background-position: center bottom;
  background-size: cover;
  min-height: 400px;
}
.external {
  background: url("./assets/images/about.webp");
  background-position: center bottom;
  background-size: cover;
  min-height: 400px;
}
.lightbulb {
  background: url("./assets/images/lightbulb.webp");
  background-position: center bottom;
  background-size: cover;
  min-height: 400px;
}
.camera {
  background: url("./assets/images/camera.webp");
  background-position: center center;
  background-size: cover;
  min-height: 400px;
}
.type {
  background: url("./assets/images/type.webp");
  background-position: center bottom;
  background-size: cover;
  min-height: 400px;
}
.vr-headset {
  background: url("./assets/images/vr.webp");
  background-position: center bottom;
  background-size: cover;
  min-height: 400px;
}
.lady {
  background: url("./assets/images/lady.webp");
  background-position: center bottom;
  background-size: cover;
  min-height: 400px;
}
.staff {
  background: url("./assets/images/staff.webp");
  background-position: center bottom;
  background-size: cover;
  min-height: 400px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* ANIMATIONS */
.st0 {
  fill: none;
  stroke: none;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st0 {
  fill: none;
  stroke: var(--gold);
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}
.grow {
  transform: scale(0);
}
.active .grow {
  transform: scale(1);
  transition: 1s;
}

.st1 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 14;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st1 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 14;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}

.st2 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 8;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st2 {
  fill: none;
  stroke: var(--light-sea-green);
  stroke-width: 8;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}

.st3 {
  fill: none;
  stroke: none;
  stroke-width: 14;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st3 {
  fill: none;
  stroke: var(--red);
  stroke-width: 14;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}
.st3-1 {
  fill: none;
  stroke: none;
  stroke-width: 26;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: none;
  animation-delay: 1s;
}
.active .st3-1 {
  fill: none;
  stroke: var(--red);
  stroke-width: 26;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s linear forwards;
  animation-delay: 1s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.5);
}

/* TRANSITIONS */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 5.3 ease-out;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Credo from "../views/Credo.vue";
import Consulting from "../views/Consulting.vue";
import Expertise from "../views/Expertise.vue";
import Chat from "../views/Chat.vue";
import Team from "../views/Team.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Credo",
    name: "Credo",
    component: Credo,
  },
  {
    path: "/consulting",
    name: "Consulting",
    component: Consulting,
  },
  {
    path: "/expertise",
    name: "Expertise",
    component: Expertise,
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
  },
  {
    path: "/partners",
    name: "Team",
    component: Team,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),

  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

export default router;
